// from w3cschool- https://www.w3schools.com/howto/howto_js_accordion.asp
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .active, .accordion:hover {
    background-color: #ccc;
  }
  
  .accordion:after {
    content: '\002B';
    color: #777;
    float: right;
    font-weight: bold;
    font-size: 50px;
    line-height: 20px !important;
    margin-left: 5px;
  }
  
  .active:after {
    content: "\2212";
    font-size: 50px;
    line-height: 20px !important;
  }
  
  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .inline {
    display: inline;
  }

  .contact-us {
    margin-top: 10px;
    margin-bottom: 10px;
  }
