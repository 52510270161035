$restaurant-name-font-size:1em;
$restaurant-font-size: 0.8em;

.active-sortBy {
    font-weight: bold;
    text-decoration: underline !important;
}

.choice-award-top {
    color: $choice-award-top-color;
}

.choice-award-runner {
    color: $choice-award-runner-up;
}

.choice-award-gold {
    color: $choice-award-gold-color;
}

.sponser {
    color: gold;
    margin-left: -4px;
    vertical-align: -25%;
    width:20px;
}

.restaurant-type {
    width: 500px;

    ul {
        margin-bottom: 1px;
    }

    .list-inline-item {
        margin-right: 1.5rem;
    }
}

.list-item {
    border-color: white;
    padding-left: 0;
}

.restaurant-list-container {
    border-style: groove;
    border-width: 2px;
    height: 1200px;
    

    li {
        padding:0 8px;
        margin-bottom: 0;
    }
}

.restaurant-distance {
    font-size: 0.9em;
}

.restaurant-list {
    overflow: scroll;    
}

.restaurant-list-label  {
    margin-bottom: 0  !important;
}
.restaurant-item {
    padding: 0 18px 0 0;

    .accordion {
        border-radius: $border-radius;
        margin-top: 10px;
    }
}

.restaurant-info {
    border-color: $border-color;
    border-radius: $border-radius;
    border-style: solid;
    border-width: 2px;
    padding: 5px 15px;
    font-size: 0.9em;
    font-weight: bold;
    margin-left: 3.4em;

    address {
        font-weight: 300;
    }
}

.yelp-data-list {
    font-size: smaller;

    address {
        margin-bottom: 0;
    }
}

.yelp-data-item {
    div {
        padding-left: 2px;
    }
}

.restaurants-note {
    margin-bottom: 1px;
    font-size: $restaurant-font-size;
}

.restaurant-name {
    font-size: $restaurant-name-font-size;

}

.restaurant-phone {
    font-size: $restaurant-font-size;
}

.restaurant-distance {
    font-size: $restaurant-font-size;
}

.restaurant-image {
    border-radius: $border-radius;
    padding-right: 10px;
}

.restaurant-image-region {
    margin-left:-5px;
}

.restaurant-count {
    width: 200px;    
}

.restaurant-legend-body {
    padding: 5px 0;
}

.restaurant-legend-row {
    display: flex;

    div {
        padding-left: 10px;
    }
  }


/* Phone-size screens */
@media only screen and (max-width: 768px) {
    .restaurant-type {
        width: initial;
    }

    .restaurant-list-container {
        height: 500px;
    }

    .restaurant-list {
        margin-bottom: 20px;
    }
}

