$footer-text-color: white;

footer {
    background-color: $section-header-color;
    padding-top: 25px;
    margin-top: 15px;
}

.social-media {
    color:$footer-text-color;
    font-size: $social-media-image-size !important;
}

.social-media-link {
    color: $footer-text-color;
}

footer .list-inline-item {
    margin-right: 1.6rem;   
}

footer a {
    color: $footer-text-color;
    text-decoration: underline;
}

.copyright, .year {
    color: $footer-text-color;
}

.footer-pipe {
    color: $footer-text-color;
}