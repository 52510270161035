/*
Variables and functionality common to all stylesheets
*/

// colors
$vegan-color: #00FF00;
$vegetarian-color: #FF8000;
$raw-vegan-color: #0000FF;
$not-verified: #422663;
$choice-award-top-color: #ccac00;
$choice-award-runner-up:  #008080;
$choice-award-gold-color: #DAA520;
$border-color: #D3D3D3;
$border-radius: 20px;
$background-color: white;
$search-box-size: 30px;
$section-header-color: #3b8529;
$card-head-color:#dff0d8;
$scrollbar-handle-color:lightgray;
$scrollbar-radius: 30px;
$social-media-image-size:40px;