.map {
    width: 100%;
    height: 700px;
}

.raw-vegan-token {
    color: $raw-vegan-color;
}

.vegan-token {
    color: $vegan-color; 
}

.vegetarian-token {
    color: $vegetarian-color;
} 

.not-verified-token {
    color: $not-verified
}

.icon-shift-driving {
    margin-left: -5px;
}

.icon-shift-transit {
    margin-left: -7px;
}

.icon-shift-walking {
    margin-left: -8px;
}

.icon-shift-bicyle {
    margin-left: -15px;
}

.restaurant-directions {
    margin-top: -10px;
}

/* Phone-size screens */
@media only screen and (max-width: 768px) {
    .map {
        height: 400px;
    }
    .restaurant-directions-container {
        margin-bottom: 35px;
    }
}